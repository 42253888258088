<template>
    <div class="apply-container">
        <van-field  
        class="patient-field" 
        v-model="inquiryName"  
        label="患者资料" 
        label-width="100px" 
        :placeholder="type === 0 ? '新建' : '请选择'" 
        right-icon="arrow" 
        input-align="right" 
        readonly
        @click="selectOrCreate()"/>
        <van-field v-model="params.linkMan"  label="联系人姓名" label-width="120px" placeholder="请填写" input-align="right"/>
        <van-field v-model="params.linkWx"  label="联系人微信号" label-width="120px" placeholder="请填写" input-align="right"/>
        <van-field v-model="params.linkMobile"  label="联系人手机号" label-width="120px" placeholder="请填写" input-align="right"/>
        <div class="btn">
            <van-button size="large" color="#0060C2" @click="createForm()" :disabled="isApply">提交报名</van-button>
        </div>
    </div>
</template>
<script>
import { Field,  Button, Toast  } from 'vant';
import {mapGetters} from 'vuex'
import patientApi from '@api/patient/patientApi.js'
import ApplyApi from '@api/testMatch/applyApi.js'
import {toPath} from '@/utils/common';
import { type } from 'os';
export default {
    components: {
        [Field.name]: Field,  
        [Button.name]: Button
    },
    data() {
        return {
            type: 1,       // 0 新建 1 选择
            inquiryName: '',
            params: {
                ddMemberId: '',
                projectId: '',          // 项目id
                projectName: '',        // 项目名称
                inquiryId: '',          // 患者id
                linkMan: '',            // 联系人姓名
                linkWx: '',             // 联系人微信
                linkMobile: ''          // 联系人手机号
            },
            isApply: false
        }
    },
    created() {
        // 获取用户信息
        const { projectId , projectName, inquiryId, inquiryName, ddMemberId } = this.$route.query;
        Object.assign(this.params, {projectId, projectName, inquiryId})
        Object.assign(this, {inquiryName: inquiryName || ''})
        if(ddMemberId!=null){
            this.params.ddMemberId=ddMemberId;
        }
        console.log("push to apply id:---"+ddMemberId);
        // 判断是否有患者
        patientApi.hasInquiry().then(res =>{
            // console.log(res.data)
            this.type = Number(res.data)
        })
    },
    computed: {
      ...mapGetters([
            'isWeixin'
        ])
    },
    methods:{
        selectOrCreate() {
            const {projectId, projectName, ddMemberId} = this.params
            this.type === 1 ? this.$router.replace({
                path: '/selectPatient',
                query: {projectId, projectName, ddMemberId}
            }) : this.$router.replace({
                path: '/createPatient',
                query: {projectId, projectName, ddMemberId}
            })
            
        },
        // 提交报名
        createForm() {
            const { params } = this
            const regMobile = /^1[3456789]\d{9}$/
            const verify = {
                inquiryId: '请选择一位患者',
                linkMan: '请填写联系人姓名',
                linkMobile: '请填写手机号'
            }
            for(let k  in verify){
                if(!params[k]) return Toast(verify[k])
                if(k === 'linkMobile' && !regMobile.test(params[k])) return Toast('请输入正确的手机号')
            }
            this.isApply = true
            ApplyApi.saveData({...this.params}).then(res => {
                this.isApply = false
                // wx.miniProgram.redirectTo({
                //     url: '/pages/web/index/index?path=' + encodeURIComponent(`/applySuccess?projectId=${params.projectId}`),
                // })
                // 微信端报名成功后跳转至dysuccess页面，处理订阅逻辑 2020-1-3
                const path = this.isWeixin ? '/dySuccess': '/applySuccess'
                toPath(this, {path, query:{projectId:params.projectId}}, '', 'replace')
            }).catch(err => {
                this.isApply = false
            })
        }
    }
}
</script>

<style lang="less"  scoped="scoped">
.apply-container {
    min-height: 100vh;
    background: #F7F7F7;
    padding-top: 8px;
    .patient-field {
        margin-bottom: 8px;
    }
    .van-cell {
        padding: 15px;
        font-size:16px;
        color:#2A2A2E;
    }
    .btn {
        width: 100%;
        padding: 50px 40px;
        button{
            height:50px;
            border-radius:10px;
            font-size: 18px;
            font-weight:500;
        }
    }
}
</style>
